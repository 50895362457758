<!--
* @description:
* @fileName tpl-grid.vue
* @author hvv
* @date 2022/01/12 11:52:48
!-->
<template>
  <div>
    <div class="layout">
      <div class="gridBox">
        <template v-for="(item, index) in res.list" :key="index">
          <div class="gridItem">
            <img :src="item.img" alt="" class="menu-img" />
            <div class="menu-title">{{ item.title }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'tpl-grid',
    props: {
      res: Object,
    },
    setup(props) {
      const state = reactive({})
    },
  })
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
  .gridBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .gridItem {
      width: 20%;
      text-align: center;
      .menu-img {
        width: 44px;
        height: 44px;
      }
      .menu-title {
        font-size: 12px;
      }
    }
  }
</style>
